import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { Navbar, Nav, Button, Dropdown, Container, Modal, Form, DropdownButton } from "react-bootstrap";
import axios from "axios";
import "./Header.css";

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showQueueModal, setShowQueueModal] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchingDots, setSearchingDots] = useState("");
    const [queueDuration, setQueueDuration] = useState(0); // Queue Duration
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userIdentifier, setUserIdentifier] = useState("");
    const [selectedWeightClass, setSelectedWeightClass] = useState("Lightweight");
    const [matchFound, setMatchFound] = useState(false);
    const [matchAccepted, setMatchAccepted] = useState(false);
    const [declinedMatch, setDeclinedMatch] = useState(false);
    const [penaltyTimeout, setPenaltyTimeout] = useState(null); // For penalty timeout
    const [ws, setWs] = useState(null);
    const weightClasses = ["Lightweight", "Middleweight", "Heavyweight"];

    const navigate = useNavigate(); // useNavigate for routing

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) verifyToken(token);
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('/api/auth/verify-token', {
                        headers: { Authorization: token },
                    });
                    console.log("Verify-token response:", response.data); // Debugging
                    setIsLoggedIn(true);
                    setUserIdentifier(response.data.username);
                    setIsAdmin(response.data.is_admin === 1); // Check admin status
                } catch (error) {
                    console.error('Token verification failed', error);
                    setIsLoggedIn(false);
                }
            }
        };
    
        fetchUserDetails();
    }, []);
    
    

    useEffect(() => {
        let dotsInterval;
        let timerInterval;

        if (isSearching) {
            dotsInterval = setInterval(() => {
                setSearchingDots((prev) => (prev.length >= 3 ? "" : prev + "."));
            }, 500);

            timerInterval = setInterval(() => {
                setQueueDuration((prev) => prev + 1);
            }, 1000);
        } else {
            setSearchingDots("");
            setQueueDuration(0);
        }

        return () => {
            clearInterval(dotsInterval);
            clearInterval(timerInterval);
        };
    }, [isSearching]);

    const handleLogin = async () => {
        try {
            const response = await axios.post("/api/auth/login", { email, password });
            const token = response.data.token;
            localStorage.setItem("token", token);
            setIsLoggedIn(true);
            setShowLoginModal(false);
            setUserIdentifier(response.data.username);
        } catch (error) {
            console.error("Login failed", error);
            alert("Login failed. Please check your credentials.");
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        setUserIdentifier("");
    };

    const verifyToken = async (token) => {
        try {
            const response = await axios.get("/api/auth/verify-token", {
                headers: { Authorization: token },
            });
            setIsLoggedIn(true);
            setUserIdentifier(response.data.username);
            localStorage.setItem("userId", response.data.userId);
        } catch (error) {
            console.error("Token verification failed", error);
            setIsLoggedIn(false);
        }
    };

    const handleQueue = () => {
        const userId = localStorage.getItem("userId");
        if (!userId) {
            alert("User ID not found. Please log in again.");
            return;
        }

        const newWs = new WebSocket("wss://esportsboxing.co");
        setWs(newWs);
        setIsSearching(true);

        newWs.onopen = () => {
            newWs.send(
                JSON.stringify({
                    type: "joinQueue",
                    userId,
                    weightClass: selectedWeightClass,
                })
            );
        };

        newWs.onmessage = (message) => {
            const data = JSON.parse(message.data);

            if (data.type === "matchFound") {
                setMatchFound(true);
                setIsSearching(false);
            } else if (data.type === "matchDeclined") {
                if (data.userDeclined) {
                    setDeclinedMatch(true); // Show declined modal for the user who declined
                } else {
                    setIsSearching(true); // Requeue the opponent
                }
                setMatchFound(false);
                setMatchAccepted(false);
            } else if (data.type === "penaltyTimeout") {
                setPenaltyTimeout(data.timeout); // Set penalty timeout duration
                setShowQueueModal(true);
            } else if (data.type === "taleOfTheTape") {
                // Redirect to TaleOfTheTape page with match data
                navigate("/tale-of-the-tape", {
                    state: { matchId: data.matchId, players: data.players, weightClass: data.weightClass },
                });
            }
        };

        newWs.onerror = (error) => console.error("WebSocket error:", error);
        newWs.onclose = () => console.log("WebSocket closed");
    };

    const handleAcceptMatch = () => {
        const userId = localStorage.getItem("userId");
        if (ws && userId) {
            ws.send(
                JSON.stringify({
                    type: "acceptMatch",
                    userId,
                })
            );
            setMatchAccepted(true);
        }
    };

    const handleDeclineMatch = () => {
        const userId = localStorage.getItem("userId");
        if (ws && userId) {
            ws.send(
                JSON.stringify({
                    type: "declineMatch",
                    userId,
                })
            );
            setMatchFound(false);
            setDeclinedMatch(true);
        }
    };

    const handleModalClose = () => {
        const userId = localStorage.getItem("userId");
        if (ws && userId) {
            ws.send(
                JSON.stringify({
                    type: "leaveQueue",
                    userId,
                    weightClass: selectedWeightClass,
                })
            );
        }
        setShowQueueModal(false);
        setMatchFound(false);
        setMatchAccepted(false);
        setDeclinedMatch(false);
        setIsSearching(false);
        setPenaltyTimeout(null);
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src="media/webtest.png" alt="Logo" style={{ width: 75, marginRight: 10 }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/matches">Matches</Nav.Link>
                        <Nav.Link as={Link} to="/teams">Gyms</Nav.Link>
                        <Nav.Link as={Link} to="/standings">Rankings</Nav.Link>
                    </Nav>
                    {isLoggedIn ? (
                        <>
                            <Button variant="success" className="me-3" onClick={() => setShowQueueModal(true)}>
                                Find Match!
                            </Button>
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="secondary">{userIdentifier}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`/profile/${userIdentifier}`}>Profile</Dropdown.Item>
                                    {isAdmin && (
                                        <Dropdown.Item as={Link} to="/configurations">Configurations</Dropdown.Item>
                                    )}
                                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    ) : (
                        <Button variant="warning" onClick={() => setShowLoginModal(true)}>Login</Button>
                    )}
                </Navbar.Collapse>
            </Container>

            {/* Login Modal */}
            <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button onClick={handleLogin} className="w-100 mt-3">Login</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Queue Modal */}
            <Modal show={showQueueModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {penaltyTimeout
                            ? "Queue Timeout"
                            : declinedMatch
                                ? "Matchmaking Declined"
                                : matchFound
                                    ? matchAccepted
                                        ? "Match Accepted!"
                                        : "Match Found!"
                                    : "Find Match"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {penaltyTimeout ? (
                        <div className="text-center">
                            <p>You are currently on a penalty timeout.</p>
                            <p>Timeout duration: {penaltyTimeout / 60000} minutes.</p>
                            <Button variant="danger" onClick={handleModalClose}>Close</Button>
                        </div>
                    ) : declinedMatch ? (
                        <div className="text-center">
                            <p>You have declined matchmaking. Please queue again to find a match.</p>
                            <Button variant="danger" onClick={handleModalClose}>Close</Button>
                        </div>
                    ) : matchFound ? (
                        matchAccepted ? (
                            <div className="text-center">
                                <p>Waiting for your opponent to accept...</p>
                                <Button variant="danger" onClick={handleModalClose}>Cancel</Button>
                            </div>
                        ) : (
                            <div className="text-center">
                                <p>A match has been found! Accept or decline.</p>
                                <Button onClick={handleAcceptMatch} className="me-2">Accept</Button>
                                <Button onClick={handleDeclineMatch}>Decline</Button>
                            </div>
                        )
                    ) : isSearching ? (
                        <div className="text-center">
                            <p>Searching for Match{searchingDots}</p>
                            <p>Queue Duration: {queueDuration}s</p>
                            <Button variant="danger" onClick={handleModalClose}>Cancel</Button>
                        </div>
                    ) : (
                        <div className="text-center">
                            <DropdownButton
                                title={selectedWeightClass}
                                onSelect={(weight) => setSelectedWeightClass(weight)}
                            >
                                {weightClasses.map((weight, idx) => (
                                    <Dropdown.Item key={idx} eventKey={weight}>{weight}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                            <Button variant="primary" className="mt-3" onClick={handleQueue}>
                                Queue
                            </Button>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </Navbar>
    );
};

export default Header;
