import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Configurations.css';

const Configurations = () => {
    const [matches, setMatches] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMatches = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("Authorization token is missing.");
                return;
            }

            try {
                const response = await axios.get("/api/admin/all-matches", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log("Matches fetched:", response.data);

                // Flatten nested arrays if necessary
                const fetchedMatches = Array.isArray(response.data[0])
                    ? response.data[0] // Use the first nested array
                    : response.data; // Use directly if not nested

                setMatches(fetchedMatches);
            } catch (err) {
                console.error("Error fetching matches:", err);
                setError("Failed to load matches.");
            }
        };

        fetchMatches();
    }, []);

    const handleGeneratePost = async (matchId) => {
        const token = localStorage.getItem("token");
        try {
            await axios.post(`/api/admin/generate-post/${matchId}`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            alert("Post generated successfully!");
        } catch (err) {
            console.error("Error generating post:", err);
            alert("Failed to generate post.");
        }
    };

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="configurations">
            <h1>Admin Configurations</h1>
            <table>
                <thead>
                    <tr>
                        <th>Match ID</th>
                        <th>Player 1</th>
                        <th>Player 2</th>
                        <th>Winner</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {matches.map((match) => (
                        <tr key={match.match_id}>
                            <td>{match.match_id}</td>
                            <td>{match.player1}</td>
                            <td>{match.player2}</td>
                            <td>{match.winner}</td>
                            <td>
                                <button
                                    onClick={() => handleGeneratePost(match.match_id)}
                                    className="generate-post-button"
                                >
                                    Generate POST
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Configurations;
