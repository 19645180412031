import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './PostDetail.css';

const PostDetail = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                console.log(`Fetching post with ID: ${postId}`);
                const response = await axios.get(`/api/posts/${postId}`);
                console.log('API Response:', response.data);

                // Adjust to access the first element of the array if the response is an array
                setPost(Array.isArray(response.data) ? response.data[0] : response.data);
            } catch (err) {
                console.error('Error fetching post:', err);
                setError('Failed to load post content.');
            }
        };

        fetchPost();
    }, [postId]);

    if (error) return <div className="error">{error}</div>;
    if (!post) return <div>Loading...</div>;

    return (
        <div className="post-detail">
            <div className="post-header">
                <h1>{post.title}</h1>
                <h2>{post.subtitle}</h2>
            </div>
            {post.image_url && (
                <img
                    src={process.env.PUBLIC_URL + post.image_url}
                    alt={post.title}
                    className="post-image"
                />
            )}
            <div className="post-content">
                <p>{post.content}</p>
            </div>
            <button className="back-button" onClick={() => window.history.back()}>
                Go Back
            </button>
        </div>
    );
};

export default PostDetail;
