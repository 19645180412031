import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./TaleOfTheTape.css";

const TaleOfTheTape = () => {
    const location = useLocation();
    const matchId = location?.state?.matchId;
    const [redCorner, setRedCorner] = useState(null);
    const [blueCorner, setBlueCorner] = useState(null);
    const [title, setTitle] = useState(null); // For title details
    const [isPending, setIsPending] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (!matchId) {
            console.error("Match ID is undefined. Cannot fetch match data.");
            return;
        }

        const fetchMatchData = async () => {
            try {
                const response = await axios.get(`/api/match/${matchId}`);
                const { redCorner, blueCorner, title, isPending, isAdmin } = response.data;
                setRedCorner(redCorner);
                setBlueCorner(blueCorner);
                setTitle(title); // Set title details if it exists
                setIsPending(isPending);
                setIsAdmin(isAdmin);
            } catch (error) {
                console.error("Error fetching match data:", error);
            }
        };

        fetchMatchData();
    }, [matchId]);

    if (!redCorner || !blueCorner) {
        return <div>Loading Tale of the Tape...</div>;
    }

    return (
        <div className="tale-of-tape-container">
            <video autoPlay loop muted playsInline className="background-video">
                <source src="/media/background.mp4" type="video/mp4" />
            </video>
            {isPending && (
                <div className="results-pending">Results Pending</div>
            )}
            <div className="tale-of-tape">
                {/* Red Corner */}
                <div className="player-side">
                    <img
                        src={`/profile_pictures/${redCorner.profilePicture}` || "/profile_pictures/default.png"}
                        alt="Red Corner Profile"
                        className="profile-image"
                    />
                    <img
                        src={`/profile_pictures/${redCorner.fighterPicture}` || "/profile_pictures/default.png"}
                        alt="Red Fighter"
                        className="fighter-image"
                    />
                </div>

                {/* Center Stats */}
                <div className="center-stats-container">
                    <div className="title-section">
                        <img src="media/webtest.png" alt="Logo" style={{ width: 250, marginRight: 10 }} />
                    </div>
                    {title && (
                        <div className="title-banner">
                            <img
                                src={`/awards/${title.image_url}`}
                                alt={title.name}
                                className="title-image"
                            />
                            <h2 className="title-name">{title.name}</h2>
                        </div>
                    )}
                    <div className="center-stats-title">TALE OF THE TAPE</div>
                    <div className="center-stats-content">
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.username}</div>
                            <div className="stat-label">Name</div>
                            <div className="stat-value-right">{blueCorner.username}</div>
                        </div>
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.wins}</div>
                            <div className="stat-label">Wins</div>
                            <div className="stat-value-right">{blueCorner.wins}</div>
                        </div>
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.losses}</div>
                            <div className="stat-label">Losses</div>
                            <div className="stat-value-right">{blueCorner.losses}</div>
                        </div>
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.draws}</div>
                            <div className="stat-label">Draws</div>
                            <div className="stat-value-right">{blueCorner.draws}</div>
                        </div>
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.knockouts}</div>
                            <div className="stat-label">Knockouts</div>
                            <div className="stat-value-right">{blueCorner.knockouts}</div>
                        </div>
                        <div className="stat-row">
                            <div className="stat-value-left">{redCorner.camp}</div>
                            <div className="stat-label">Camp</div>
                            <div className="stat-value-right">{blueCorner.camp}</div>
                        </div>
                    </div>
                </div>

                {/* Blue Corner */}
                <div className="player-side">
                    <img
                        src={`/profile_pictures/${blueCorner.profilePicture}` || "/profile_pictures/default.png"}
                        alt="Blue Corner Profile"
                        className="profile-image"
                    />
                    <img
                        src={`/profile_pictures/${blueCorner.fighterPicture}` || "/profile_pictures/default.png"}
                        alt="Blue Fighter"
                        className="fighter-image"
                    />
                </div>
            </div>

            {/* Action Buttons */}
            <div className="button-container">
                <button className="action-button">Report Results</button>
                <button className="action-button">Forfeit Match</button>
            </div>

            {/* Admin Options */}
            {isAdmin && (
                <button className="admin-button" onClick={() => alert("Admin Options")}>
                    Admin Options
                </button>
            )}
        </div>
    );
};

export default TaleOfTheTape;
