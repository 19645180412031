// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import UserProfile from './components/UserProfile';
import Register from './components/Register';
import TaleOfTheTape from './components/TaleOfTheTape';
import Homepage from './components/Homepage';
import adminMatches from './components/adminMatches'
import PostDetail from './components/PostDetail';
import Configurations from './components/Configurations';

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
        <Routes>
          {/* Exact match for home */}
          <Route path="/" element={<Homepage />} />

          <Route path="/posts/:postId" element={<PostDetail />} />

          {/* Profile route with username */}
          <Route path="/profile/:username" element={<UserProfile />} />

          {/* Register route */}
          <Route path="/register" element={<Register />} />

          {/* TOTT route */}
          <Route path="/tale-of-the-tape" element={<TaleOfTheTape />} />

          {/* Admin route */}
          <Route path="/admin" element={<adminMatches />} />

          <Route path="/configurations" element={<Configurations />} />;

          {/* Optional: Add a 404 page for unmatched routes */}
          <Route path="*" element={<h1>404 - Page Not Found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
