import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Homepage.css';

const Homepage = () => {
    const [data, setData] = useState({ featured: null, posts: [] });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHomepageData = async () => {
            try {
                const response = await axios.get('/api/homepage');
                console.log('Homepage API Response:', response.data); // Debug log
                setData(response.data || { featured: null, posts: [] });
            } catch (err) {
                console.error('Error fetching homepage data:', err);
                setError('Failed to load homepage content.');
            }
        };
        fetchHomepageData();
    }, []);

    if (error) return <div className="error">{error}</div>;

    return (
        <div className="homepage">
            {/* Hero Section */}
            {data.featured && (
                <div className="hero-section">
                    <div className="hero-image-container">
                        <img
                            src={data.featured.image_url || 'https://via.placeholder.com/1200x600'}
                            alt="Featured"
                            className="hero-image"
                        />
                    </div>
                    <div className="hero-content">
                        <h1 className="hero-title">{data.featured.title}</h1>
                        <p className="hero-subtitle">{data.featured.subtitle}</p>
                        <Link to={`/posts/${data.featured.id}`} className="hero-link">
                            Read More
                        </Link>
                    </div>
                </div>
            )}

            {/* Posts Grid */}
            <div className="posts-section">
                <h2 className="section-title">Latest Posts</h2>
                <div className="posts-grid">
                    {Array.isArray(data.posts) && data.posts.length > 0 ? (
                        data.posts.map((post) => (
                            <div className="post-card" key={post.id}>
                                <Link to={`/posts/${post.id}`}>
                                    <img
                                        src={post.image_url || 'https://via.placeholder.com/300x200'}
                                        alt={post.title}
                                        className="post-card-image"
                                    />
                                    <div className="post-card-content">
                                        <h3 className="post-card-title">{post.title}</h3>
                                        <p className="post-card-subtitle">{post.subtitle}</p>
                                    </div>
                                </Link>
                            </div>
                        ))
                    ) : (
                        <p className="no-posts">No posts available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Homepage;
