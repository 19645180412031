// src/components/UserProfile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import './UserProfile.css';
import { Link } from 'react-router-dom';

const UserProfile = () => {
    const { username } = useParams(); // Username from the route parameters
    const [profile, setProfile] = useState(null);
    const [loggedInUserId, setLoggedInUserId] = useState(localStorage.getItem('userId') || null);
    const [awards, setAwards] = useState([]);
    const [matchHistory, setMatchHistory] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [isEditingPicture, setIsEditingPicture] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            // Verify the token and set the logged-in user ID
            axios
                .get('/api/auth/verify-token', { headers: { Authorization: token } })
                .then((response) => {
                    setLoggedInUserId(response.data.userId);
                    localStorage.setItem('userId', response.data.userId); // Cache userId
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    setLoggedInUserId(null);
                });
        }

        // Fetch profile data for the given username
        fetchProfileData(username);
    }, [username]);

    const fetchProfileData = async (username) => {
        try {
            const profileResponse = await axios.get(`/api/user/profile/username/${username}`);
            setProfile(profileResponse.data);

            const userId = profileResponse.data.user_id; // Extract user_id from profile data

            // Fetch additional data: seasons, awards, season records, and match history
            const seasonsResponse = await axios.get(`/api/user/seasons`);
            setSeasons(seasonsResponse.data.seasons);
            setSelectedSeason(seasonsResponse.data.activeSeason);

            const awardsResponse = await axios.get(`/api/user/awards/${userId}`);
            setAwards(awardsResponse.data.awards || []);

            if (seasonsResponse.data.activeSeason) {
                fetchSeasonRecords(userId, seasonsResponse.data.activeSeason);
                fetchMatchHistory(userId, seasonsResponse.data.activeSeason);
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const fetchSeasonRecords = async (userId, seasonId) => {
        try {
            const seasonRecordsResponse = await axios.get(`/api/user/season-records/${userId}/${seasonId}`);
            const { wins, losses, draws, knockouts, bouts, rounds } = seasonRecordsResponse.data;

            // Update profile with season records
            setProfile((prevProfile) => ({
                ...prevProfile,
                wins,
                losses,
                draws,
                knockouts,
                bouts,
                rounds,
            }));
        } catch (error) {
            console.error('Error fetching season records:', error);
        }
    };

    const fetchMatchHistory = async (userId, seasonId) => {
        try {
            const matchHistoryResponse = await axios.get(`/api/user/match-history/${userId}/${seasonId}`);
            setMatchHistory(matchHistoryResponse.data.matchHistory);
        } catch (error) {
            console.error('Error fetching match history:', error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUploadPicture = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('profilePicture', selectedFile);

        try {
            const response = await axios.post(`/api/user/upload-profile-picture/${profile.user_id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            // Update profile picture URL in profile state
            setProfile((prevProfile) => ({
                ...prevProfile,
                profile_pic_url: response.data.profile_pic_url,
            }));

            setIsEditingPicture(false);
            setSelectedFile(null);
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    if (!profile) return <div>Loading...</div>;

    return (
        <div className="container">
            <div className="fighter-info-container">
                <div className="fighter-info">
                    <div className="profile-pic-container">
                        <img
                            src={profile.profile_pic_url ? `/profile_pictures/${profile.profile_pic_url}` : '/profile_pictures/default.png'}
                            alt="Profile"
                            className="fighter-image"
                        />
                        {loggedInUserId === profile.user_id && (
                            <div
                                className="edit-icon"
                                onClick={() => setIsEditingPicture(true)}
                                title="Edit Profile Picture"
                            >
                                ✏️
                            </div>
                        )}
                    </div>
                    <div className="fighter-details">
                        <h1>{profile.username}</h1>
                        <p>Status: {profile.is_active ? 'Active' : 'Inactive'}</p>
                        <p>Bouts: {profile.bouts}</p>
                        <p>Rounds: {profile.rounds}</p>
                        <p>KOs: {profile.knockouts}</p>
                        <div className="record-box">
                            <div className="win">{profile.wins} Wins</div>
                            <div className="loss">{profile.losses} Losses</div>
                            <div className="draw">{profile.draws} Draws</div>
                        </div>
                    </div>
                </div>

                <div className="profile-section current-championships">
                    <h2>Current Championships</h2>
                    {awards.length > 0 ? (
                        <ul>
                            {awards.map((award, index) => (
                                <li key={index}>
                                    <img src={`/awards/${award.image_url}`} alt="Title" className="title-image" />
                                    {award.name} - {award.weight_class}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No current championships.</p>
                    )}
                </div>
            </div>

            <div className="profile-section season-records">
                <h2>Season Records</h2>
                <div className="season-tabs">
                    {seasons.map((season) => (
                        <button
                            key={season.season_id}
                            className={`season-tab ${season.season_id === selectedSeason ? 'active' : ''}`}
                            onClick={() => {
                                setSelectedSeason(season.season_id);
                                fetchSeasonRecords(profile.user_id, season.season_id);
                                fetchMatchHistory(profile.user_id, season.season_id);
                            }}
                        >
                            {season.season_name}
                        </button>
                    ))}
                </div>
            </div>

            <div className="profile-section match-history">
                <h2>Match History</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Opponent</th>
                            <th>Result</th>
                            <th>Event</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matchHistory.length > 0 ? (
                            matchHistory.map((match, index) => (
                                <tr key={index}>
                                    <td>{formatDate(match.date)}</td>
                                    <td>
                                        <Link
                                            to={`/profile/${match.opponent_username || match.opponent_name}`} // Fallback for undefined usernames
                                            className="opponent-link"
                                        >
                                            {match.opponent_name} ({match.opponent_record || '0-0-0'})
                                        </Link>
                                    </td>
                                    <td>{match.result} ({match.match_status})</td>
                                    <td>{match.event || 'Local Show'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No match history available for this season.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Modal show={isEditingPicture} onHide={() => setIsEditingPicture(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Select a new profile picture</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button variant="primary" onClick={handleUploadPicture} className="w-100">
                            Upload
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UserProfile;
